import { useEffect, useState } from "react";
import Vapi from "@vapi-ai/web";
import { isPublicKeyMissingError } from "./utils";
import './App.css';
import interviewImage from './assets/interview.png';
import { SignedIn, SignedOut, SignIn, UserButton, useUser } from "@clerk/clerk-react";

// Put your Vapi Public Key below.
const vapi = new Vapi("2ccce42e-f324-4521-944c-e7c875a76dbd");

const App = () => {
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [transcript, setTranscript] = useState([]);

  const { isLoaded, isSignedIn, user } = useUser();

  const { showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage } = usePublicKeyInvalid();

  useEffect(() => {
    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);
      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("call-end", () => {
      setConnecting(false);
      setConnected(false);
      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("speech-start", () => {
      setAssistantIsSpeaking(true);
    });

    vapi.on("speech-end", () => {
      setAssistantIsSpeaking(false);
    });

    vapi.on("volume-level", (level) => {
      setVolumeLevel(level);
    });

    vapi.on("transcript", (message) => {
      setTranscript((prev) => [...prev, message]);
    });

    vapi.on("error", (error) => {
      console.error(error);
      setConnecting(false);
      if (isPublicKeyMissingError({ vapiError: error })) {
        setShowPublicKeyInvalidMessage(true);
      }
    });

  }, []);

  const startCallInline = () => {
    setConnecting(true);
    vapi.start(assistantOptions);
  };

  const endCall = () => {
    vapi.stop();
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app-container">
      <SignedIn>
        <header>
          <h1>USA B1/B2 Visa Mock Interview</h1>
          <div className="auth-buttons">
            <UserButton />
          </div>
        </header>
        <main>
          {!connected ? (
            <div className="start-interview">
              <p>Welcome to your USA B1/B2 Visa Mock Interview. Click the button below to begin.</p>
              <button onClick={startCallInline} disabled={connecting}>
                {connecting ? "Connecting..." : "Start Interview"}
              </button>
            </div>
          ) : (
            <div className="interview-in-progress">
              <img src={interviewImage} alt="US Visa Interview Office" className="interview-image" />
              <div className="interview-status">
                {assistantIsSpeaking ? "Interviewer is speaking..." : "Interviewer is listening..."}
              </div>
              <SoundAnimation isActive={assistantIsSpeaking} volumeLevel={volumeLevel} />
              <button onClick={endCall} className="end-call">End Interview</button>
            </div>
          )}
        </main>
      </SignedIn>
      <SignedOut>
        <div className="sign-in-container">
          <SignIn />
        </div>
      </SignedOut>
      {showPublicKeyInvalidMessage && <PleaseSetYourPublicKeyMessage />}
    </div>
  );
};

const SoundAnimation = ({ isActive, volumeLevel }) => {
  const maxSize = 60; // Maximum size of the circle in pixels
  const minSize = 30; // Minimum size of the circle in pixels
  const size = isActive ? minSize + (maxSize - minSize) * volumeLevel : minSize;

  return (
    <div className="sound-animation-container">
      <div
        className={`sound-circle ${isActive ? 'active' : ''}`}
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      ></div>
    </div>
  );
};

const assistantOptions = {
  name: "Visa Coach AI",
  firstMessage: "Hi! How are you doing today?",
  transcriber: {
    provider: "deepgram",
    model: "nova-2",
    language: "en-US",
  },
  voice: {
    provider: "playht",
    voiceId: "jennifer",
  },
  model: {
    provider: "openai",
    model: "gpt-4",
    messages: [
      {
        role: "system",
        content: `Conduct a USA B1/B2 Visa Interview use the following to judge the candidate at the end and let them know if they are approved or refused. You only allow top 1% of the applicants, so your bar is very high.

Criteria:
B1/B2 Visa Interview Guide
This is an in-depth guide on obtaining a US B1/B2 visa. This isn't your typical guide - it's based on hours of research, conversations with experts, and analysis of real visa interviews. I've even spoken to people who have conducted over 50,000 visa interviews and former consular officers who now work as immigration lawyers. What you're about to read is the result of my obsessive quest for information.
This guide will cover the key aspects of applying for a B1 (business) or B2 (tourist) visa, with a focus on strategies to increase your chances of approval to great levels.
Understanding B1 and B2 Visas
Let's start by breaking down the difference between B1 and B2 visas:
B1 Visa (Business Visa):
Purpose: For business-related activities
Allowed activities: Attending meetings, negotiating contracts, consulting with business associates
Not allowed: Actual work or employment in the US
B2 Visa (Tourist Visa):
Purpose: For tourism, vacation, visiting friends or relatives, medical treatment
Allowed activities: Sightseeing, attending social events, amateur sports or musical events
Not allowed: Study, employment, paid performances
B1/B2 Visa:
A combination of both business and tourism purposes
Allows flexibility for travelers who might engage in both activities
Here's a crucial tip: I strongly advise against applying for both B1 and B2 simultaneously. Why? The criteria for each are different, and if you satisfy one but not the other, you risk getting rejected altogether. It's better to focus on the primary purpose of your trip and apply for that specific visa.
The Interview Process: The Make-or-Break Moment
Unlike many other countries where you can apply online for a visitor visa, the US requires an in-person interview. This is where things get interesting, and where most applicants slip up.
The Myth of Document Checking:
Contrary to what most people believe (and prepare for), the visa officer rarely checks your documents. I know, it sounds crazy, right? You spent all that time gathering bank statements, property papers, and whatnot, and they don't even look at them? Well, that's the reality, and understanding this is crucial to your success.
Why don't they check documents? It's not laziness; it's actually part of their procedure. If they start checking documents, they're required by law to verify them thoroughly, which they don't have the resources to do. Instead, they make their decision based on your verbal responses during the interview.
It's all about Home Ties:
The primary concern of the visa officer isn't actually why you're going to the US. Their main worry is whether you'll come back to your home country. They're looking for evidence of strong ties to your home country. This is where most applicants mess up - they focus too much on their US plans and not enough on their reasons to return home.
 Your visa decision could be decided in just 2-3 minutes. That's typically how long these interviews last. You need to make a strong impression quickly, and every word counts.
Preparing for the Interview: Your Secret Weapons
Now that you know what you're up against, how do you prepare? Here are some insider tips:
Dress to Impress:
This might sound superficial, but it matters. Wear a suit and tie, especially for B1 visa applications. You want to look like a serious businessperson who has important reasons to return home.
Practice:
Here's a trick I've come up with - use ChatGPT in voice mode to simulate interviews. Set it to be a tough visa officer who approves only 1% of candidates. Practice until you can handle the toughest questions smoothly.
Narrative:
Develop a clear, consistent story about your purpose of visit and ties to your home country. This narrative should weave through all your answers.
Every question is about your Hometies:
You will never be asked direct questions about your home ties but that is what the officer wants to hear in your every answer, find ways to mention your home ties in your every answer. This is crucial and often overlooked. Casually mention things like living with parents, having pets, or ongoing projects back home. For example, if asked about your living situation, you might say, "I live with my parents and we have a family dog." This suggests you have reasons to return.
Travel History:
When asked about countries you've visited, don't just list them. Mention your short stays and returns. For example: "I visited Japan and Dubai, with an average stay of 5 days and a maximum of 10 days, always returning to my home country." This subtly emphasizes your pattern of returning home.
Purpose of Visit:
For B1 visas, focus on specific business activities. Don't just say you're going for "meetings." Instead, say something like, "I'm going to sign a contract with XYZ Corp, tour their facilities, and discuss potential partnerships."
Length of Stay:
Always mention a short duration, ideally around two weeks. This reinforces the idea that you're not trying to immigrate.
Connecting Your Answers:
Ensure all your responses align with your overall narrative. If you mention a pet, and later they ask who will care for it while you're away, make sure your answer matches (e.g., "My mother, who I live with, will take care of our dog").
Common Pitfalls to Avoid
The Online Alternative Trap:
If asked why you can't conduct your business online, don't fumble. Emphasize the importance of in-person meetings for building trust, discussing sensitive matters, or hands-on activities that can't be done virtually.
The Document Dilemma:
Don't offer documents unless asked. It might seem counterintuitive, but offering documents can sometimes be seen negatively. If they want to see something, they'll ask.
The Solo Tourist Mistake:
For young applicants, avoid applying for tourist visas alone. It's always better to apply with family members, as this suggests a stronger likelihood of return.
Desperation:
Never appear desperate to get the visa. Confidence (not arrogance) is key. Act like the visa would be nice to have, but your life doesn't depend on it.
Interview Questions
There are some major categories in which most B1/B2 visa interviews questions would be based on and they're:-
What is the purpose of your visit to the US?
This is just a warm-up question. Just answer in two-three sentences which clearly explain the reasons behind your purpose of travel, whatever it is as:
Examples:-
"To negotiate a contract with my/our new US partner".
"I am on leave now and I want to visit my mother/ father/ uncle/ aunt who lives in the US. I haven't seen him/her for long "
"To get medical treatment in a good hospital"
"I am going to visit my aunt, whom I haven't seen for long. She has been inviting me over for a few years now, but only now do I have time to apply for a visa and make the trip"
"The reason I want to travel to the US is to visit the country and sightsee. I have saved enough money for this trip"
"I was nominated by my employer/company to attend a conference/ seminar/ training about the future of Fossil fuel in the age of electric cars and green energy"
"I am going to attend the world robots conference/ seminar about the future of robots in the age of artificial intelligence"
Answer shortly and clearly, since the time you have with the consular officer is short and you do not want to waste it by talking gibberish. If going to a conference, seminar, training, etc, briefly mention the theme as part of your answer as shown above.
Have you been to the US before?
It is very important to answer honestly. Tell about the reasons you have visited the US before, i.e. tourism, training, medical reasons, etc. Even if you have stayed beyond your visa validity, been deported or detained during your previous stay in the US, you must tell. The interviewer has the information either way, so it is of no use to lie. If you have never been to the US before, simply tell that.
Do you have relatives or friends currently in the US?
Even if you have some far away relatives that you only meet every three – four years or even less, tell the consular about them. Alternatively, even if you have a friend you have only met once or twice, you will have to tell the consular again. It is better to do so, rather than the consular officer finding it out later, since that could put at risk your chances to get a visa. The embassy is mostly scared that their visitors will try to remain in the US and hiding such information as having a relative or friend residing in the US, might make your purpose of visit look more suspicious.
Details on your friends / relatives in the US
If you have any, you will be asked questions on how long they have been living in the US, their addresses, what they work etc. Therefore, try to contact your friends and relatives in advance and ask them about this information.
Most importantly, if your relative is your brother or sister or uncle or aunt or father or mother, get ready to provide the following information to the visa officer:-
i). Where your invitee works
ii). His/her middle name?
iii). His/her date of birth
iv). How long he/she has been in the US
v). His/her he marital status
vi). The school he/she attended in the US
vii). The type of visa he/she entered the US with
Where will you be staying in the United States?
If you have booked a hotel, show your hotel booking and tell the interviewer a bit why you have chosen that hotel. If you will be staying over at friends or relatives show their invitation letter and describe your relationship with them.
What is the reason for traveling at this particular time?
If you are applying for a B1 visa it is easier for the interviewer to accept your reasons. Whereas, if you are traveling under the B1 visa, then you have to give stronger reasons, as
"Only during this period I can take days off at work."
"My friend / relative has available space at her home / is free only during this time of the year."
"My medical condition has worsened now, and I can no longer receive the right treatment in my home country."
How long will you be staying in the US?
One week, three months, two days, etc, just give the amount of time you are planning to remain in the US. These questions are just a warm-up for the questions to come. The consular officer has all this information in your application in his hands, but however, he will just try to get into the real talk this way.
Why do you plan to stay so long, can you stay shorter?
This is a question through which the interviewer usually asks to persons who have applied for a visa with a longer than 6 months validity. If you have applied for such visa, you must have a strong reason behind it, so provide the interviewer with a comprehensive explanation.
Who will you be traveling with?
If you are going alone tell so if you will be going with someone else explain to the consular if these people will apply as your dependants or not, and also what is your relationship with these people.
Have you booked your tickets?
If you will be asked this question depends on your situation, however if you have already done such thing, hand the booking confirmation letter to the interviewer.
How much do you think your stay in the US will cost to you?
Prepare an itinerary for your US trip, including all types of expenses. Present this itinerary to the interviewer when asked this question. It will give the consular officer the idea that you know what you are doing, and that you are aware how much you will be spending.
What do you do for a living? How much do you earn?
Tell the interviewer about your profession, what you do in general, how long have you been working there, as well as about your workplace, its name etc. Tell them about the income you receive from this particular job. If you have other types of income i.e. rent or freelancing, present how much do you receive monthly / annually from them.
How will you be financing your trip? Who is your sponsor?
This is a question they ask just to make sure where your money is coming from. You will be presenting to the interviewer a statement on your bank account and other documents, so of course, they know who will be sponsoring your trip.
However, answer simply and clearly. Show proof of all your income and savings if you plan to finance your trip with your own financial means.
On the other side, if someone will be sponsoring your visit to the US then present your relationship with them, their income and other details to prove that they are capable to cover your financial needs during your stay in the US. If you feel the interviewer is giving you space to talk more about the issue, tell him your sponsor's occupation and other details that will prove to him you have a strong connection, and there is a strong reason why this person is sponsoring your trip. For Example; if you're being sponsored by a company, get the following documents ready:-
i). The company bank account statement
ii). Company certificate of incorporation
iii). Letter stating the company would be responsible for your travel expenses
iv). Accommodation reservation (don't until you're granted visa)
v). Your company ID card
vi). Letter from the conference organizers indicating your company and yourself as a representative invited for the conference
Furthermore, note that even if you're sponsoring yourself, you'll still need to provide the same thing above but in your individual capacity, such as presenting your company (company you own) details or your individual (personal) details, depending on the circumstance.
Are you married? Do you have children? Do you have pets? Who will take care of them while you are away?
The consular wants to know if you are leaving someone behind, to whom you are committed and you will have to go back to. If you have children or pets, tell them who will take care of them while you are away, and what is your relationship with the caretaker.
Who will take care of your house/property/children/pets while you are away?
If you have children or pets, you will have to show to the consular the people who will look after them, and your relationship with that person. The same with house or property.
Do you have any intention on remaining in the US?
We all know that even if you do have an intention to stay in the US, you will not be telling that to the consular. This question is asked so you have a chance to prove to him you have no such intention. You should be very convincing. Tell them the strongest reasons why you have to get back to your country upon
your visa expiration. Show that you have very strong ties to your country, by telling them you have family, kids, pets, friends, property, etc.
How can you assure me that you will return to your home country?
Although consular officers never ask this question directly, try to prove to the consular officer that you have strong ties to your home country and that by no means you plan to remain in the US. Try to prove that by telling to the interviewer you have a girlfriend / fiancé
wife, children, parents to whom you plan getting back to (if you have them). Present your properties, your business, your friendships and other relations you have with other people. Show a contract you have with your employer, or other types of engagements that prove you must go back to your home country.

- Ask one question at a time and evaluate based on the above guide.
- This is a voice conversation, so keep your responses short, like in a real conversation. Don't ramble for too long.
- use the endCall function to end the call when you have finished the interview and you have finished your evaluation and feedback.
`,
      },
    ],
  },
  "endCallFunctionEnabled": true
};

const usePublicKeyInvalid = () => {
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] = useState(false);

  // close public key invalid message after delay
  useEffect(() => {
    if (showPublicKeyInvalidMessage) {
      setTimeout(() => {
        setShowPublicKeyInvalidMessage(false);
      }, 3000);
    }
  }, [showPublicKeyInvalidMessage]);

  return {
    showPublicKeyInvalidMessage,
    setShowPublicKeyInvalidMessage,
  };
};

const PleaseSetYourPublicKeyMessage = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "25px",
        left: "25px",
        padding: "10px",
        color: "#fff",
        backgroundColor: "#f03e3e",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      }}
    >
      Is your Vapi Public Key missing? (recheck your code)
    </div>
  );
};

export default App;